import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './Spinner.module.scss';

const Spinner = ({ small = false }) => {
  const overlayClasses = classNames(styles['loading-icon'], {
    [styles['loading-icon--small']]: small
  });

  return <span className={overlayClasses} />;
};

Spinner.propTypes = {
  small: PropTypes.bool
};

export default Spinner;
